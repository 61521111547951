import React from 'react';
import styled from '@emotion/styled';

import PageSEO from '@zd/components/page-seo';
import { Container } from '@zd/components/grid';
import SubscribeForm from '@zd/components/forms/subscribe-form';
import PageTitle from '@zd/components/page-title';
import BackLink from '@zd/components/back-link';

import theme from '@zd/theme';

const StyledPageTitle = styled(PageTitle)`
  display: none;

  ${theme.medias.gteLarge} {
    display: block;
  }
`;

const StyledBackLink = styled(BackLink)`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`;

export default props => {
  const pageTitle = 'Newsletter';

  return (
    <>
      <PageSEO {...props} title={pageTitle} />
      <Container>
        <StyledPageTitle title={pageTitle} />
        <SubscribeForm />
        <br />
        <StyledBackLink />
      </Container>
      {/*<Instagram /> */}
    </>
  );
};
