import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import theme from '@zd/theme';

const Div = styled.div`
  margin-bottom: 1.5rem;

  ${theme.medias.gteLarge} {
    margin-bottom: 1.5rem;
  }
`;

const H1 = styled.h1`
  color: ${theme.colors.primaryBlue};
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 42px;
  text-align: left;
  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    font-size: 30px;
    text-align: left;
  }
`;

const DividerDiv = styled.div`
  width: 100px;
  height: 5px;
  background: ${theme.colors.secondaryBlue};
  margin: 0;
`;

const PageTitle = props => {
  const { title, ...rest } = props;

  return (
    <Div {...rest}>
      <H1>{title}</H1>
      <DividerDiv />
    </Div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;
