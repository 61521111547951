import React from 'react';
import styled from '@emotion/styled';

import Link from '@zd/components/link';

const StyledLink = styled(Link)`
  display: inline-block;
  color: #592cba;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 1.5rem;
  margin-bottom: 3.5rem;
`;

const BackLink = props => {
  return (
    <StyledLink href="/" title="Voltar para a página inicial" {...props}>
      ← Voltar para a página inicial
    </StyledLink>
  );
};

export default BackLink;
